.homeContainer {
  background-image: url("../../img/2nd.JPG");
  /* background-image: url('../../img/lake.png'); */

  background-position: center; /* Center the background image vertically */
  background-size: cover;
  min-height: 700px;
  min-width: 100px;
  padding: 0;

  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.content {
  /* border: 1px purple solid; */
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  color: white;
  margin-inline: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.content h1 {
  font-size: 56px;
  font-weight: bolder;
}

.content p {
  font-size: 22px;
  margin: 15px 0;
  max-width: 550px;
  letter-spacing: 2px;
}

.content a {
  color: inherit;
  text-decoration: none;
  background-image: url('../../img/sn2.png');
  /* background-color: rgb(8, 50, 174); */
  padding: 12px 24px;
  font-size: 22px;
  border-radius: 10px;
}
