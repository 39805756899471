.post {
    display: flex;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .post-image {
    width: 200px;
    height: 150px;
    margin-right: 20px;
  }
  
  .post-content {
    flex-grow: 1;
  }
  
  .post-title {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .post-description {
    margin-bottom: 10px;
  }
  
  .post-link {
    text-decoration: none;
    color: blue;
  }
  
  .post-link:hover {
    text-decoration: underline;
  }
  