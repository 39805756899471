.hobbiesContainer{
}

.hobbyCard{
    padding: 20px;
}

.hobbyRow{
    justify-content: center;
    /* background-color: aqua; */

}


#r{
    justify-items: center;
}
