.footer {
    background-color: #205cca;
    color: #fff;
    padding: 20px 0;
    
  }
  
  .footer p {
    margin-bottom: 5px;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  